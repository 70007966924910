import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";

export interface Payment {
  payid: number,
  pmatri_id: string,
  pname: string,
  pemail: string,
  paddress: string,
  paymode: string,
  pactive_dt: Date,
  p_plan: string,
  plan_duration: number,
  profile: number,
  video: string,
  chat: string,
  p_no_contacts: number,
  p_amount: string,
  p_bank_detail: string,
  pay_id: string,
  p_msg: number,
  r_profile: number,
  r_cnt: number,
  r_sms: number,
  exp_date: Date,
  current_plan: string,
  franchise_comm_per: string,
  franchise_comm_amt: string,
  franchise_id: number,
  tax_name: string,
  tax_per: number,
}

export interface PaymentInfo {
  errors: unknown;
  payment: Payment;
  payments: Payment[];
}

@Module
export default class PaymentModule extends VuexModule implements PaymentInfo {
  errors = {};
  payment = {} as Payment;
  payments = [] as Payment[];

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { error };
  }
  //
  // @Mutation
  //     [Mutations.SET_PAYMENTS](payments: Payment[]) {
  //   this.payments = payments;
  // }

  @Action
      [Actions.GET_PAYMENTS](payload) {
    this.context.commit(Mutations.SET_ERROR, null);
    return ApiService.query(`/admin/payment`, { params: payload })
        .then((response)  => {
          // this.context.commit(Mutations.SET_AUTH, data);
          // this.context.commit(Mutations.UPDATE_USER_STATUS_MUTATION, payload);
          return response;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.error);
        });
  }

  @Action
      [Actions.CREATE_PAYMENT](payload) {
    this.context.commit(Mutations.SET_ERROR, null);
    return ApiService.post(`/admin/payment`, payload)
        .then((response)  => {
          // this.context.commit(Mutations.SET_AUTH, data);
          // this.context.commit(Mutations.UPDATE_USER_STATUS_MUTATION, payload);
          return response;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.error);
        });
  }
}
