import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

export interface MatchedStatus {
    s_id: number,
    o_id: number,
    status: string,
    updated_on: Date
}

export interface MatchedStatusInfo {
    errors: unknown;
    // matched: Matched;
    matched_status_list: MatchedStatus[];
}

@Module
export default class MatchedStatusModule extends VuexModule implements MatchedStatusInfo {
    errors = {};
    // matched = {} as Matched;
    matched_status_list = [] as MatchedStatus[];

    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

@Mutation
    [Mutations.SET_ERROR](error) {
    this.errors = {error};
}
@Action
    [Actions.UPDATE_MATCHED_STATUS](payload) {
    this.context.commit(Mutations.SET_ERROR, null);
    return ApiService.post(`/admin/matchedStatus`, payload)
        .then(({data}) => {
            return data;
        })
        .catch(({response}) => {
            this.context.commit(Mutations.SET_ERROR, response.data.error);
        });
}
}
