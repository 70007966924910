import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface EduDetail {
    edu_id: number;
    edu_name: string;
    status: string;
}

export interface EduDetailInfo {
    errors: unknown;
    eduDetail: EduDetail[];
}

@Module
export default class EduDetailModule extends VuexModule implements EduDetailInfo {
    errors = {};
    eduDetail = [] as EduDetail[];



    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

    @Mutation
        [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }


    @Action
        [Actions.GET_EDU_DETAILS]() {
        return ApiService.get("/eduDetail")
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }
}
