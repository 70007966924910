import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import Discover from "@/class/Discover";
import store from "@/store";

export interface DiscoverInfo {
    errors: unknown;
    discover: Discover[];
}

@Module
export default class SiteModule extends VuexModule {
    errors = {};
    discover = [] as Discover[];


    get getDiscovers(): Discover[] {
        return this.discover;
    }
    get getErrors() {
        return this.errors;
    }

    @Mutation
        [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }

    @Action
        [Actions.GET_DISCOVER]() {
        this.context.commit(Mutations.SET_ERROR);
        const isAuthenticated = store.getters.isUserAuthenticated;
        const resource = isAuthenticated ? '/discover' : '/discover/guest';
        return ApiService.get(resource)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.error);
            });
    }

    @Action
        [Actions.GET_DISCOVER_USERS](payload) {
        this.context.commit(Mutations.SET_ERROR);
        return ApiService.query("/discover/filterby", {params: payload})
            .then((response) => {
                return response;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.error);
            });
    }
}
