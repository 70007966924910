import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface PaymentMethod {
    pay_id: number;
    pay_name: string;
    pay_email: string;
    merchant_id: string;
    check_desc: string;
    ccavenue_id: string;
    working_key: string;
    access_code: string;
    use_in_payment: string;
    bank_detail: string;
    status: string;
}

export interface PaymentMethodInfo {
    errors: unknown;
    paymentMethodInfo: PaymentMethod[];
}

@Module
export default class PaymentMethodModule extends VuexModule implements PaymentMethodInfo {
    errors = {};
    paymentMethodInfo = [] as PaymentMethod[];


    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

    @Mutation
        [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }


    @Action
        [Actions.GET_PAYMENT_METHODS]() {
        return ApiService.query("/paymentMethods", {
            params: {
                status:"Approved"
            }})
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }
}
