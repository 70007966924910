import { Module } from 'vuex';

interface TimezoneState {
    timezone: string;
}

const TimezoneModule: Module<TimezoneState, any> = {
    state: () => ({
        timezone: ''
    }),
    mutations: {
        setTimezone(state, timezone: string) {
            state.timezone = timezone;
        }
    },
    actions: {
        fetchTimezone({ commit }) {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            commit('setTimezone', timezone);
        }
    }
};

export default TimezoneModule;
