import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";

export default {
    state: {
        caste: []
    },
    getters: {
    },
    actions: {
        [Actions.GET_CASTE]({ commit }) {
            return ApiService.get("/caste")
                .then((response) => {
                    return response;
                })
                .catch(({response}) => {
                    // commit(Mutations.SET_ERROR, response.data.errors);
                });
        },
        [Actions.GET_CASTE_BY_RELIGION]({ commit }, id) {
            return ApiService.query("/caste/byReligion", { params: {religion: id} })
                .then(({data}) => {
                    return data;
                })
                .catch(({response}) => {
                    // commit(Mutations.SET_ERROR, response.data.errors);
                });
        }
    },
    mutations: {
    },
};
