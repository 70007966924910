import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
        {
            path: "/dashboard",
            name: "dashboard",
            component: () => import("@/views/Dashboard.vue"),
        },
      // {
      //   path: "/contactus",
      //   name: "contact-us",
      //   component: () =>
      //       import("@/views/pages/contactus/Contactus.vue"),
      // },
      {
        path: "/home",
        name: "home",
        component: () =>
            import("@/views/pages/home/Home.vue"),
      },
      // {
      //   path: "/search",
      //   name: "search-page",
      //   component: () =>
      //       import("@/views/pages/search/Search.vue")
      // },
      // {
      //   path: "/plan",
      //   name: "plan-page",
      //   component: () =>
      //       import("@/views/pages/plan/Plan.vue"),
      // },
      // {
      //   path: "/stripe-success",
      //   name: "stripe-success",
      //   component: () =>
      //       import("@/views/pages/plan/Success.vue"),
      // },
      // {
      //   path: "/stripe-cancel",
      //   name: "stripe-cancel",
      //   component: () =>
      //       import("@/views/pages/plan/Cancel.vue"),
      // },
      {
        path: "/detail/:id",
        name: "detail-page",
        component: () =>
            import("@/views/pages/detail/Detail.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/profile/:id",
        name: "profile-page",
        component: () =>
            import("@/views/pages/profile/Profile.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/users",
        name: "users",
        component: () =>
            import("@/views/adminPages/users/Users.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/matches/:id/:mobile",
        name: "matches",
        component: () =>
            import("@/views/adminPages/matches/Matches.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/add-user",
        name: "add-user",
        component: () =>
            import("@/views/adminPages/addUser/AddUser.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/payments",
        name: "payments",
        component: () =>
            import("@/views/adminPages/payments/Payments.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/matchedActivity",
        name: "matchedActivity",
        component: () =>
            import("@/views/adminPages/matchActivity/MatchActivity.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/userActivity",
        name: "userActivity",
        component: () =>
            import("@/views/adminPages/userActivity/UserActivity.vue"),
        meta: { requiresAuth: true }
      },
      {
        path: "/matchesSent/:id",
        name: "matchesSent",
        component: () =>
            import("@/views/adminPages/matchesSent/MatchesSent.vue"),
        meta: { requiresAuth: true }
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/signin/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/signup/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/passwordReset/PasswordReset.vue"),
      },
      {
        path: "/register-success",
        name: "register-success",
        component: () =>
          import("@/views/crafted/authentication/signup/RegisterSuccess.vue"),
      },
      {
        path: "/activate",
        name: "activate",
        component: () =>
          import("@/views/pages/activate/Activate.vue"),
      },
      {
        path: "/stripe-checkout",
        name: "stripe-checkout",
        component: () =>
          import("@/views/pages/stripeCheckout/StripeCheckout.vue"),
      },
    ],
  },
  // {
  //   path: "/multi-step-sign-up",
  //   name: "multi-step-sign-up",
  //   component: () =>
  //     import("@/views/crafted/authentication/MultiStepSignUp.vue"),
  // },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { "id_token": JwtService.getToken() });


  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = store.getters.isUserAuthenticated;
  if (requiresAuth && !isAuthenticated) {
    // Redirect to the login page
    next({ name: 'sign-in' });
  } else {
    // Proceed to the next route
    next();
  }


  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
