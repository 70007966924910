import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import Religion from "@/class/Religion";
import Mtongue from "@/class/Mtongue";
import Caste from "@/class/Caste";
import Country from "@/class/Country";
import {constants} from "@/core/services/Constants";
import MatchBy from "@/class/MatchBy";
import {Mutation} from "vuex-module-decorators";
import {User} from "@/store/modules/UserModule";
import store from "@/store";

interface PaginationState {
    items: User[];
    itemCount: number;
    pageSize: number;
    pageNo: number;
    pageCount: number;
    loading: boolean;
}

interface UserState {
    users: PaginationState;
}

const createPaginationState = (): PaginationState => ({
    items: [],
    itemCount: 0,
    pageSize: 10,
    pageNo: 1,
    pageCount: 0,
    loading: false,
});

export default {
    state: {
        search:  {
            searchText: null,
            gender: [] as string[],
            mstatus: [] as string[],
            age_frm: null,
            age_to: null,
            religion: [] as Religion[],
            caste: [] as Caste[],
            mtongue: [] as Mtongue[],
            country: [] as Country[]
        },
        matchBy: [] as MatchBy[],
        users: createPaginationState(),
    },

    getters: {

        currentSearch(state) {
            return state.search;
        },
        users(state) {
            return state.users;
        },
        currentMatchBy(state) {
            return state.matchBy;
        },


        // getErrors() {
        //     return this.errors;
        // }
    },
    actions: {
        [Actions.SEARCH_USER]({ state, commit }) {
            commit(Mutations.SET_ERROR, null);

            let new_payload = {};

            new_payload['searchText'] = state?.search?.searchText;

            new_payload['gender'] = state?.search?.gender;

            const mstatus = state?.search?.mstatus?.filter(obj => obj !== constants.ANY);
            new_payload['mstatus'] = mstatus?.map(function(item) {
                return item;
            });

            new_payload['age_frm'] = state?.search?.age_frm;
            new_payload['age_to'] = state?.search?.age_to;

            const religion = state?.search?.religion?.filter(obj => obj?.religion_id !== 0);
            new_payload['religion'] = religion?.map(function(item) {
                return item.religion_id;
            });
            const caste = state?.search?.caste?.filter(obj => obj.caste_id !== 0);
            new_payload['caste'] = caste.map(function(item) {
                return item.caste_id;
            });

            const mtongue = state?.search?.mtongue?.filter(obj => obj?.mtongue_id !== 0);
            new_payload['mtongue'] = mtongue?.map(function(item) {
                return item?.mtongue_id;
            });

            const country = state?.search?.country?.filter(obj => obj?.country_id !== 0);
            new_payload['country'] = country?.map(function(item) {
                return item?.country_id;
            });

            const status = state?.search?.status?.filter(obj => obj !== constants.ANY);
            new_payload['status'] = status?.map(function(item) {
                return item;
            });
            new_payload['pageNo'] = state?.users?.pageNo;

            return ApiService.query("/admin/users/filterUser", { params: new_payload })
                .then(async(response) => {
                    const headersData = JSON.parse(response.headers["pagination"]);
                    commit(Mutations.SET_USERS, {
                        items: response.data,
                        itemCount: headersData.ItemCount,
                        pageSize: headersData.PageSize,
                        pageNo: headersData.PageNo,
                        pageCount: headersData.PageCount,
                    });
                    return response;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.errors);
                });
        },
        [Actions.UPDATE_PAGENO]({ commit, dispatch }: any, pageNo: number) {
            commit(Mutations.SET_PAGENO, pageNo);
            dispatch(Actions.SEARCH_USER);
        },
        [Actions.SET_USERS]({ commit, dispatch }: any, data) {
            commit(Mutations.SET_USERS, data);
        },
        [Actions.UPDATE_SEARCH]({ commit, dispatch }: any, search: UserState) {
            commit(Mutations.SET_SEARCH, search);
            commit(Mutations.RESET_PAGINATION);
            // dispatch(Actions.SEARCH);
        },
        [Actions.STORE_SEARCH]({ commit }, payload) {
            commit(Mutations.SET_SEARCH, payload);
        },
        [Actions.CLEAR_SEARCH]({ commit }) {
            commit(Mutations.PURGE_SEARCH);
        },
        [Actions.STORE_MATCH_BY]({ commit }, payload) {
            commit(Mutations.SET_MATCH_BY, payload);
        },
        [Actions.CLEAR_MATCH_BY]({ commit }) {
            commit(Mutations.PURGE_MATCH_BY);
        },

        [Actions.SEARCH]({ state, commit }, payload) {

            return ApiService.query("/admin/users/searchUser", { params: payload})
                .then((response) => {
                    // commit(Mutations.SET_USERS, response.data);
                    return response;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.errors);
                });
        },
    },
    mutations: {
        [Mutations.PURGE_SEARCH](state) {
            state.search = {};
        },
        [Mutations.SET_SEARCH](state, search) {
            state.search = search;
        },
        [Mutations.SET_PAGENO](state: UserState, pageNo: number) {
            state.users.pageNo = pageNo;
        },
        [Mutations.RESET_PAGINATION](state: UserState) {
            state.users = createPaginationState();
        },
        [Mutations.SET_USERS](state: UserState, payload: { items: User[], itemCount: number, pageSize: number, pageNo: number, pageCount: number }) {
            state.users.items = payload.items;
            state.users.itemCount = payload.itemCount;
            state.users.pageSize = payload.pageSize;
            state.users.pageNo = payload.pageNo;
            state.users.pageCount = payload.pageCount;
        },
        [Mutations.PURGE_MATCH_BY](state) {
            state.matchBy = [];
        },
        [Mutations.SET_MATCH_BY](state, matchBy) {
            state.matchBy = matchBy;
        },
        // [Mutations.SET_ERROR](state) {
        //     state.errors = {...error};
        // }
    }
};
