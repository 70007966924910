enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  ADD_USER = "addUser",
  UPDATE_USER = "updateUser",
  UPDATE_PROFILE = "updateProfile",
  UPLOAD_PROFILE = "uploadProfile",
  DELETE_PROFILE_PHOTO = "deleteProfilePhoto",
  FORGOT_PASSWORD = "forgotPassword",
  VERIFY_CODE = "verifyCode",
  RESET_PASSWORD = "resetPassword",
  RESET_USER_PASSWORD = "resetUserPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  STORE_SEARCH = "storeSearch",
  STORE_MATCH_BY = "storeMatchBy",
  SEARCH_USER = "searchUser",
  SEARCH = "search",
  CLEAR_SEARCH = "clearSearch",
  CLEAR_MATCH_BY = "clearMatchBy",
  GET_RELIGION = "getReligion",
  CLEAR_RELIGION = "clearReligion",
  GET_CASTE = "getCaste",
  GET_CASTE_BY_RELIGION = "getCasteByReligion",
  GET_MTONGUE = "getMtongue",
  CLEAR_MTONGUE = "clearMtongue",
  GET_COUNTRY = "getCountry",
  GET_STATE = "getState",
  GET_STATES_BY_COOUNTRY = "getStatesByCountry",
  GET_PROFILE = "getProfile",
  GET_EDU_DETAILS = "getEduDetails",
  GET_OCCUPATIONS = "getOccupations",
  GET_DESIGNATIONS = "getDesignations",
  GET_PLANS = "getPLans",
  GET_PAYMENT_METHODS = "getPaymentMethods",
  CREATE_CHECKOUT_SESSION = "createCheckoutSession",
  CONTACT_US = "contactUs",
  GET_SIGNED_FILE = "getSignedFile",
  GET_SIGNED_UPLOAD_REQUEST = "getSignedUploadRequest",
  UPLOAD = "upload",
  DELETE_FILE = "deleteFile",
  SITE_CONFIG = "siteConfig",
  GET_DISCOVER = "getDiscover",
  GET_DISCOVER_USERS = "getDiscoverUsers",
  GET_MATCH_OF_DAY = "getMatchOfDay",
  GET_ADMIN_MATCHES_USERS = 'getAdminMatcheUsers',
  GET_MATCHED_USERS = 'getMatchedUsers',
  GET_MATCHES = 'getMatches',
  USER_DETAIL = 'userDetail',
  GET_USERS = 'getUsers',
  UPDATE_USER_STATUS = 'updateUserStatus',
  SEND_MATCHES = 'sendMatches',
  GET_STATS = 'getStats',
  GET_STATS_BY_COUNTRY = 'getStatsByCountry',
  GET_STATS_BY_DAY = 'getStatsByDay',
  GET_STATS_PAY_BY_DAY = 'getStatsPayByDay',
  GET_STATS_TODAY = 'getStatsToday',
  GET_PAYMENTS = 'getPayments',
  GET_MATCH_ACTIVITY = 'getMatchActivity',
  GET_MEMBERSHIP_PLANS = 'getMembershipPlans',
  CREATE_PAYMENT = 'createPayment',
  GET_MATCH_SENT ='getMatchSent',
  DELETE_MATCH_SENT = 'deleteMatchSent',
  GET_USER_ACTIVITY = 'getUserActivity',
  UPDATE_PAGENO = 'updatePageNo',
  UPDATE_SEARCH = 'updateSearch',
  SET_USERS = "setUsers",
  UPLOAD_IMAGE = "uploadImage",
  GENERATE_USER_TOKEN = "generateUserToken",
  UPDATE_MATCHED_STATUS = "updateMatchedStatus",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_DRAFT_USER = "setDraftUser",
  SET_ACTIVATION_DATA = "setActivationData",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  PURGE_SEARCH = "purgeSearch",
  SET_SEARCH = "setSearch",
  PURGE_MATCH_BY = "purgeMatchBy",
  SET_MATCH_BY = "setMatchBy",
  SET_RELIGION = "setReligion",
  PURGE_RELIGION = "purgeReligion",
  SET_SITE_CONFIG = "setSiteConfig",
  SET_USER_DETAIL = "setUserDetail",
  UPDATE_USER_STATUS_MUTATION = 'updateUserStatusMutation',
  SET_USERS = 'setUsers',
  RESET_PAGINATION = 'resetPagination',
  SET_PAGENO = 'setPageNo',
  UPDATE_USER_IN_USERS = 'updateUserInUsers',
  SET_MATCH_ACTIVITY = 'setMatchActivity'

}

export { Actions, Mutations };
