import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";


export interface User {
    index_id: number,
    fb_id: string,
    matri_id: string,
    prefix: string,
    title: string,
    description: string,
    keyword: string,
    terms: string,
    email: string,
    password: string,
    cpassword: string,
    cpass_status: string,
    m_status: string,
    profileby: string,
    time_to_call: string,
    reference: string,
    username: string,
    firstname: string,
    lastname: string,
    gender: string,
    birthdate: Date,
    birthtime: Date,
    birthplace: string,
    tot_children: number,
    status_children: string,
    edu_detail: string,
    income: string,
    occupation: string,
    emp_in: string,
    designation: string,
    religion: number,
    caste: number,
    subcaste: string,
    gothra: string,
    star: string,
    moonsign: string,
    horoscope: string,
    manglik: string,
    m_tongue: number,
    height: number,
    weight: number,
    b_group: string,
    complexion: string,
    bodytype: string,
    diet: string,
    smoke: string,
    drink: string,
    languages_known: string,
    address: string,
    country_id: number,
    state_id: number,
    city: number,
    phone: string,
    mobile: string,
    contact_view_security: string,
    residence: string,
    father_name: string,
    mother_name: string,
    father_living_status: string,
    mother_living_status: string,
    father_occupation: string,
    mother_occupation: string,
    profile_text: string,
    looking_for: string,
    family_details: string,
    family_type: string,
    family_status: string,
    no_of_brothers: number,
    no_of_sisters: number,
    no_marri_brother: number,
    no_marri_sister: number,
    part_frm_age: number,
    part_to_age: number,
    part_bodytype: string,
    part_diet: string,
    part_smoke: string,
    part_drink: string,
    part_income: string,
    part_emp_in: string,
    part_occupation: string,
    part_designation: string,
    part_expect: string,
    part_height: number,
    part_height_to: number,
    part_complexion: string,
    part_mtongue: string,
    part_religion: string,
    part_caste: string,
    part_manglik: string,
    part_star: string,
    part_edu: string,
    part_country_living: string,
    part_state: string,
    part_city: string,
    part_resi_status: string,
    hobby: string,
    hor_check: string,
    hor_photo: string,
    photo_protect: string,
    photo_pswd: string,
    video: string,
    video_approval: string,
    video_url: string,
    video_view_status: string,
    photo_view_status: string,
    photo1: string,
    photo1_approve: string,
    photo2: string,
    photo2_approve: string,
    photo3: string,
    photo3_approve: string,
    photo4: string,
    photo4_approve: string,
    photo5: string,
    photo5_approve: string,
    photo6: string,
    photo6_approve: string,
    photo7: string,
    photo7_approve: string,
    photo8: string,
    photo8_approve: string,
    childAcceptance: string,
    reg_date: Date,
    ip: string,
    agent: string,
    agent_approve: string,
    last_login: Date,
    status: string,
    fstatus: string,
    logged_in: string,
    adminrole_id: number,
    franchised_by: string,
    adminrole_view_status: string,
    mobile_verify_status: string,
    token: string,

    age: string,
    userCountry: object,
    userReligion: object,
    userCaste: object,
    userEducation: object,
    userOccupation: object,
    userDesignation: object

}

export interface UserInfo {
    errors: unknown;
    user: User;
    users: User[];
}

export default {
    state: {
        errors: {},
        user: {} as User,
        users: [] as User[]
    },

    getters: {

        loggedUser(state): User {
            return state.loggedUser;
        },

        getDraftUser(state) {
            return state.user;
        },
        getUsers(state) {
            return state.users;
        },
        getUser: (state) => {
            return (index_id) => {
                return state.user && state.user.index_id == index_id ? state.user : null;
            };
        },

        /**
         * Get authentification errors
         * @returns array
         */
        getErrors(state) {
            return state.errors;
        }
    },
    actions: {

        [Actions.RESET_USER_PASSWORD]({state, commit}, payload) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.post("/admin/users/reset-password", payload)
                .then(({data}) => {
                    // this.context.commit(Mutations.SET_AUTH, data);
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },

        [Actions.UPDATE_USER_STATUS]({state, commit}, payload) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.post("/admin/users/updateStatus", payload)
                .then((response) => {
                    // this.context.commit(Mutations.SET_AUTH, data);
                    commit(Mutations.UPDATE_USER_STATUS_MUTATION, response?.data);
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },
        [Actions.SEND_MATCHES]({state, commit}, payload) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.post("/admin/users/sendMatches", payload)
                .then((response) => {
                    // this.context.commit(Mutations.SET_AUTH, data);
                    // this.context.commit(Mutations.UPDATE_USER_STATUS_MUTATION, payload);
                    return response;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },
        [Actions.GET_STATS]({state, commit}, payload) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.get(`/admin/users`, `getStats?gender=${payload?.gender}&period=${payload?.period}`)
                .then((response) => {
                    // this.context.commit(Mutations.SET_AUTH, data);
                    // this.context.commit(Mutations.UPDATE_USER_STATUS_MUTATION, payload);
                    return response;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },
        [Actions.GET_STATS_BY_COUNTRY]({state, commit}, payload) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.get(`/admin/users`, `getStatsByCountry`)
                .then((response) => {
                    // this.context.commit(Mutations.SET_AUTH, data);
                    // this.context.commit(Mutations.UPDATE_USER_STATUS_MUTATION, payload);
                    return response;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },
        [Actions.GET_STATS_BY_DAY]({state, commit}, payload) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.get(`/admin/users`, `getStatsByDay?gender=${payload?.gender}&period=${payload?.period}&timezone=${payload?.timezone}`)
                .then((response) => {
                    // this.context.commit(Mutations.SET_AUTH, data);
                    // this.context.commit(Mutations.UPDATE_USER_STATUS_MUTATION, payload);
                    return response;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },
        [Actions.GET_STATS_PAY_BY_DAY]({state, commit}, payload) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.get(`/admin/users`, `getStatsPayByDay?gender=${payload?.gender}&period=${payload?.period}&timezone=${payload?.timezone}`)
                .then((response) => {
                    // this.context.commit(Mutations.SET_AUTH, data);
                    // this.context.commit(Mutations.UPDATE_USER_STATUS_MUTATION, payload);
                    return response;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },
        [Actions.GET_STATS_TODAY]({state, commit}, payload) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.get(`/admin/users`, `getStatsToday?gender=${payload?.gender}&period=${payload?.period}&timezone=${payload?.timezone}`)
                .then((response) => {
                    // this.context.commit(Mutations.SET_AUTH, data);
                    // this.context.commit(Mutations.UPDATE_USER_STATUS_MUTATION, payload);
                    return response;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },

        [Actions.GET_USER_ACTIVITY]({state, commit}, payload) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.query("/admin/users/userActivity", {params: payload})
                .then(({data}) => {
                    return data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },
        [Actions.USER_DETAIL]({state, commit}, id) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.get(`/admin/users/${id}`)
                .then(({data}) => {
                    commit(Mutations.SET_USER_DETAIL, data);
                    return data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },

        [Actions.UPDATE_PROFILE]({state, commit}, payload) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.update("/admin/users", payload.index_id, payload)
                .then(({data}) => {
                    commit(Mutations.SET_USER_DETAIL, data);
                    commit(Mutations.UPDATE_USER_IN_USERS, data);
                    return data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },
        [Actions.UPDATE_USER]({state, commit}, data) {
            commit(Mutations.SET_USER_DETAIL, data);
            // commit(Mutations.UPDATE_USER_IN_USERS, data);
        }
    },
    mutations: {

        [Mutations.SET_ERROR](state, error) {
            state.errors = {...error};
        },

        // [Mutations.SET_DRAFT_USER](state, user) {
        //     state.user = user;
        // },

        [Mutations.SET_USERS](state, users: User[]) {
            state.users = users;
        },
        [Mutations.UPDATE_USER_IN_USERS](state, updatedUser: User) {
            const index = state.users?.items.findIndex(user => user?.index_id == updatedUser?.index_id);
            if (index !== -1) {
                const existingUser = state.users.items[index];
                Object.keys(existingUser).forEach(key => {
                    if (key in updatedUser) {
                        existingUser[key] = updatedUser[key];
                    }
                });
            }
        },

        [Mutations.SET_USER_DETAIL](state, data) {
            if (!state.user) {
                state.user = {};
            }
            Object.assign(state.user, data);
        },

        [Mutations.UPDATE_USER_STATUS_MUTATION]({state, commit}, payload) {
            const data = store.getters.users;
            const items = data?.items.map(user => {
                if (payload?.ids.includes(user.index_id)) {
                    return {...user, status: payload?.action};
                }
                return user;
            });
            store.dispatch(Actions.SET_USERS, {...data, items}, {root: true});
        }
    }
};
