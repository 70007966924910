import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";
import {User} from "@/store/modules/UserModule";
import Religion from "@/class/Religion";
import Caste from "@/class/Caste";
import Mtongue from "@/class/Mtongue";
import Country from "@/class/Country";
import MatchBy from "@/class/MatchBy";
export interface Matched {
    o_id: number,
    my_id: number,
    other_id: number,
    sent_on: Date
}

interface PaginationState {
    items: Matched[];
    itemCount: number;
    pageSize: number;
    pageNo: number;
    pageCount: number;
    loading: boolean;
}

interface MatchActivityState {
    matched_list: PaginationState;
}

const createPaginationState = (): PaginationState => ({
    items: [],
    itemCount: 0,
    pageSize: 10,
    pageNo: 1,
    pageCount: 0,
    loading: false,
});

export default {
    state: {
        matched_list: createPaginationState(),
    },

    getters: {

        matchActivity(state) {
            return state.matched_list;
        },

    },
    actions: {


        // [Actions.SEARCH]({ state, commit }, payload) {
        //
        //     return ApiService.query("/admin/users/searchUser", { params: payload})
        //         .then((response) => {
        //             // commit(Mutations.SET_USERS, response.data);
        //             return response;
        //         })
        //         .catch(({response}) => {
        //             commit(Mutations.SET_ERROR, response.data.errors);
        //         });
        // },
        [Actions.GET_MATCH_ACTIVITY]({state, commit}, payload) {
            commit(Mutations.SET_ERROR, null);
            let new_payload = {};
            new_payload['pageNo'] = state?.matched_list?.pageNo;
            return ApiService.query(`/admin/matchActivity`, {params: new_payload})
                .then((response) => {
                    const headersData = JSON.parse(response.headers["pagination"]);
                    commit(Mutations.SET_MATCH_ACTIVITY, {
                        items: response?.data?.rows,
                        itemCount: headersData.ItemCount,
                        pageSize: headersData.PageSize,
                        pageNo: headersData.PageNo,
                        pageCount: headersData.PageCount,
                    });
                    return response;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },
        [Actions.GET_MATCH_SENT]({state, commit}, value) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.query(`/admin/matchActivity/${value?.id}`, {params: value?.payload})
                .then((response) => {
                    // this.context.commit(Mutations.SET_AUTH, data);
                    // this.context.commit(Mutations.UPDATE_USER_STATUS_MUTATION, payload);
                    return response;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },
        [Actions.DELETE_MATCH_SENT]({state, commit}, id) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.delete(`/admin/matchActivity/${id}`)
                .then(({data}) => {
                    return data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },
        [Actions.UPDATE_PAGENO]({ commit, dispatch }: any, pageNo: number) {
            commit(Mutations.SET_PAGENO, pageNo);
            dispatch(Actions.GET_MATCH_ACTIVITY);
        },
    },
    mutations: {
        [Mutations.SET_ERROR](state, error) {
            state.errors = {...error};
        },
        [Mutations.SET_PAGENO](state: MatchActivityState, pageNo: number) {
            state.matched_list.pageNo = pageNo;
        },
        [Mutations.RESET_PAGINATION](state: MatchActivityState) {
            state.matched_list = createPaginationState();
        },
        [Mutations.SET_MATCH_ACTIVITY](state: MatchActivityState, payload: { items: Matched[], itemCount: number, pageSize: number, pageNo: number, pageCount: number }) {
            state.matched_list.items = payload.items;
            state.matched_list.itemCount = payload.itemCount;
            state.matched_list.pageSize = payload.pageSize;
            state.matched_list.pageNo = payload.pageNo;
            state.matched_list.pageCount = payload.pageCount;
        },
    }
};
