import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Religion {
    religion_id: number;
    religion_name: string;
    status: string;
}

export interface ReligionInfo {
    errors: unknown;
    religion: Religion[];
}

@Module
export default class ReligionModule extends VuexModule implements ReligionInfo {
    errors = {};
    religion = [] as Religion[];



    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

    @Mutation
        [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }


    @Action
        [Actions.GET_RELIGION]() {
        return ApiService.get("/religion")
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }
}
