const constants = {
    USER: "user",
    UNMARRIED: "Unmarried",
    PROFILE: "Profile",
    PROFILE_BASIC: "profile_basic",
    PROFILE_RELIGION: "profile_religion",
    PROFILE_LOCATION: "profile_location",
    PROFILE_PROFESSION: "profile_profession",
    PROFILE_FAMILY: "profile_family",
    PREFERENCE_BASIC: "preference_basic",
    PREFERENCE_RELIGION: "preference_religion",
    PREFERENCE_LOCATION: "preference_location",
    PREFERENCE_PROFESSION: "preference_profession",
    PREFERENCE: "Preference",
    PHOTOS: "Photos",
    SECURITY: "Security",
    ANY: "Any",
    IMAGE_SIZE: "imagesize",
    USER_DEFAULT_PROFILE_PHOTO: "/media/svg/avatars/blank",
    RECOMMENDED_MATCH: 'recommended_match',
    AUTO_MATCH: 'auto_match',
    BRIDE: 'Bride',
    GROOM: 'Groom',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    SUSPENDED: 'Suspended'
};

export {
    constants
};
