import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Country {
    country_id: number;
    country_code: string;
    country_name: string;
    status: string;
}

export interface CountryInfo {
    errors: unknown;
    country: Country[];
}

@Module
export default class CountryModule extends VuexModule implements CountryInfo {
    errors = {};
    country = [] as Country[];



    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

    @Mutation
        [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }


    @Action
        [Actions.GET_COUNTRY]() {
        return ApiService.get("/country")
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }
}
