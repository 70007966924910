import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import Site from "@/class/Site";

export interface SiteInfo {
    errors: unknown;
    site: Site;
}

@Module
export default class SiteModule extends VuexModule implements SiteInfo {
    errors = {};
    site = {} as Site;


    get getSite(): Site {
        return this.site;
    }
    get getErrors() {
        return this.errors;
    }

    @Mutation
        [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }

    @Mutation
        [Mutations.SET_SITE_CONFIG](site) {
        this.site = site;
    }
    @Action
        [Actions.SITE_CONFIG]() {
        this.context.commit(Mutations.SET_ERROR);
        return ApiService.get("/site")
            .then(({data}) => {
                this.context.commit(Mutations.SET_SITE_CONFIG, data);
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.error);
            });
    }
}
