import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Designation {
    desg_id: number;
    desg_name: string;
    status: string;
}

export interface DesignationInfo {
    errors: unknown;
    designation: Designation[];
}

@Module
export default class DesignationModule extends VuexModule implements DesignationInfo {
    errors = {};
    designation = [] as Designation[];



    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

    @Mutation
        [Mutations.SET_ERROR](error) {
        this.errors = { ...error };
    }


    @Action
        [Actions.GET_DESIGNATIONS]() {
        return ApiService.get("/designation")
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }
}
