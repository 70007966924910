import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import axios from "axios";
import AuthModule from "@/store/modules/AuthModule";
import store from "@/store";

export default {
    state: {
        state: [],
        errors: {}
    },
    getters: {
        getErrors(state) {
            return state.errors;
        }
    },
    actions: {
        [Actions.GET_SIGNED_FILE]({commit}, name) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.get("/aws/files/" + name)
                .then((response) => {
                    return response.data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },
        [Actions.GET_SIGNED_UPLOAD_REQUEST]({commit}, { payload, isThumbnail }) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.get(`/aws/sign?file-name=${payload?.name}&file-type=${payload?.type}`)
                .then((response) => {
                    return response.data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },

        [Actions.UPLOAD]({commit}, payload) {
            commit(Mutations.SET_ERROR, null);
            const customAxios = axios.create({
                headers: {
                    'Content-Type': payload?.file?.type,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Credentials': 'true'
                },
            });
            return customAxios.put(payload?.url, payload?.file).then((response) => {
                    return response.data;
                })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },
        async [Actions.UPLOAD_IMAGE]({ commit }, payload) {
            commit(Mutations.SET_ERROR, null);
            const customAxios = axios.create({
                headers: {
                    'Content-Type': payload?.file?.type,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Credentials': 'true'
                },
            });
            const formData = new FormData();
            formData.append('image', payload.file);
            formData.append('index_id', payload.index_id);
            formData.append('photoType', payload.photoType);
            return customAxios.post('aws/upload', formData).then((response) => {
                store.dispatch(Actions.UPDATE_USER, response.data, { root: true });
                return response.data;
            })
                .catch(({response}) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        },
        [Actions.DELETE_FILE]({commit}, { index_id, photoType, filename }) {
            commit(Mutations.SET_ERROR, null);
            return ApiService.delete(`/aws/files/${index_id}/${photoType}/${filename}`)
                .then(async ({ data }) => {
                    await store.dispatch(Actions.UPDATE_USER, data, { root: true });
                })
                .catch(({ response }) => {
                    commit(Mutations.SET_ERROR, response.data.error);
                });
        }
    },
    mutations: {
        [Mutations.SET_ERROR](state, error) {
            state.errors = { error };
        }
    },
};
