import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Contact {
    name: string,
    mobile: string,
    subject: string,
    message: string
}

export interface ContactInfo {
    errors: unknown;
    contact: ContactModule[];
}

@Module
export default class ContactModule extends VuexModule implements ContactInfo {
    errors = {};
    contact = [] as ContactModule[];


    /**
     * Get authentification errors
     * @returns array
     */
    get getErrors() {
        return this.errors;
    }

    @Mutation
        [Mutations.SET_ERROR](error) {
        this.errors = {...error};
    }


    @Action
        [Actions.CONTACT_US](payload) {
        this.context.commit(Mutations.SET_ERROR, null);
        return ApiService.post("/message", payload)
            .then(({data}) => {
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.error);
            });
    }
}
