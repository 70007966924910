import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";

export default {
    state: {
        state: []
    },
    getters: {
    },
    actions: {
        [Actions.GET_STATE]({ commit }) {
            return ApiService.get("/state")
                .then((response) => {
                    return response;
                })
                .catch(({response}) => {
                    // commit(Mutations.SET_ERROR, response.data.errors);
                });
        },
        [Actions.GET_STATES_BY_COOUNTRY]({ commit }, id) {
            return ApiService.query("/state/byCountry", { params: {country: id} })
                .then(({data}) => {
                    return data;
                })
                .catch(({response}) => {
                    // commit(Mutations.SET_ERROR, response.data.errors);
                });
        }
    },
    mutations: {
    },
};
